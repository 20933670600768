
@charset "UTF-8";

/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html {
  color: #000;
  background: #FFF;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q {
  &:before, &:after {
    content: '';
  }
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

body {
  min-width: 100%;
  font-size: 16px;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-text-size-adjust: 100%;
  background: #f2f2f2;
}

.control-wrapper {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
}

a:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.read {
  line-height: 32px;
}

.non {
  padding: 0 !important;
  margin: 0 !important;
}

.error {
  color: red;
  padding: 0 0 40px;
}

.ured {
  background: #eb5656;
}

.ublue {
  background: #67c1d3;
}

.ugreen {
  background: #75c58c;
}

.txt-ured {
  color: #eb5656;
}

.txt-ublue {
  color: #67c1d3;
}

.txt-ugreen {
  color: #75c58c;
}

img {
  vertical-align: top;
  border: 0;
}

a {
  text-decoration: none;
  color: #000;
}

.hidden {
  visibility: hidden;
}

.title, .cnt-detail .title2 {
  font-weight: bold;
  font-size: 21px;
  margin: 0 0 20px;
}

.page-title {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 3;
  left: 0;
  margin-top: -120px;
  .content-block {
    padding-left: 50px;
  }
  .pname {
    position: relative;
    display: table;
    height: 200px;
    width: 342px;
    border: 2px solid #fff;
    color: #fff;
    font-size: 24px;
    text-align: center;
    margin: 0 0 0 5px;
    span {
      font-size: 14px;
    }
    p {
      line-height: 30px;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

#page-visual {
  overflow: hidden;
  .visual {
    position: relative;
  }
  .left {
    float: left;
    width: 44%;
  }
  .right {
    float: left;
    width: 56%;
  }
  .page-visual-impact {
    position: absolute;
    right: 0;
  }
  .pop {
    position: relative;
  }
  .box {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center;
    margin: -60px 0 0;
    &.no-icon {
      margin: -20px 0 0;
    }
    &.subdir {
      margin: -80px 0 0;
      .icon-title {
        padding: 8px 0 0;
        font-size: 21px;
        line-height: 1.3;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
  .icon-title {
    padding: 8px 0 0;
    font-size: 21px;
    font-weight: bold;
  }
}

#content .intro {
  position: relative;
  padding: 50px 0 0;
  .title, .cnt-detail .title2 {
    font-size: 28px;
    text-align: center;
  }
}

.cnt-detail #content .intro .title2 {
  font-size: 28px;
  text-align: center;
}

#content .intro {
  .read {
    padding: 60px 0 60px;
    font-size: 18px;
    line-height: 32px;
  }
  .right480 {
    float: right;
    width: 440px;
    margin: 60px 0 0 40px;
  }
}

.small-line {
  width: 50px;
  height: 3px;
  background-color: #ff9900;
}

.middle-line {
  width: 100px;
  height: 3px;
  background-color: #ff9900;
  margin: 0 auto;
  &.no {
    margin: 0;
  }
  &.m20 {
    margin: 0 0 20px;
  }
  &.m40 {
    margin: 0 0 40px;
  }
  &.m60 {
    margin: 0 0 60px;
  }
  &.m80 {
    margin: 0 0 80px;
  }
  &.m100 {
    margin: 0 0 100px;
  }
  &.blue {
    background: #143f80;
  }
}

.wide-line-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 60px;
  background-color: #ff9900;
}

.wide-line-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 60px;
  background-color: #ff9900;
}

#content {
  position: relative;
  min-height: 1000px;
}

.wrapper {
  position: relative;
  padding: 0 20px 0 120px;
  transition: .5s;
}

@media screen and (max-width: 1280px) {
  .wrapper {
    padding: 0 20px;
  }
}

#header {
  position: relative;
}

#nav {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  font-size: 14px;
  .li {
    width: 100px;
    float: left;
    a {
      display: block;
      height: 40px;
      padding: 22px 0 0 10px;
      border-left: 2px solid #e2e2e3;
      box-sizing: border-box;
    }
  }
}

#logo {
  position: relative;
  text-align: center;
  padding: 0 0 0 120px;
  margin: 0;
  background: url(../img/pc/koushou.png) left top no-repeat;
  .kousyou {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    a {
      display: block;
      width: 120px;
      height: 120px;
    }
  }
  img {
    margin: 0 120px 0 0;
  }
}

#menu-sp-icon {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 120px;
  height: 120px;
  z-index: 10;
  background: #143f80 url(../img/pc/common/hamb.png) center center no-repeat;
  font-size: 0;
  display: none;
}

#menu-sp {
  position: absolute;
  width: 100%;
  top: 120px;
  left: 0;
  transition: left .5s;
  background: #fff;
  display: none;
  z-index: 100;
  padding-bottom: 80px;
  &.active {
    display: block;
  }
  .li {
    text-align: left;
    border-bottom: 2px dotted #143f80;
    font-size: 32px;
    &.on {
      background-position: left bottom !important;
      background-color: #ff9900;
      .box {
        color: #fff;
        border-top: 2px dotted #ff9900;
      }
      .category {
        display: block;
      }
    }
    &.shirou-li {
      margin: 40px 80px;
      border: none;
      text-align: center;
    }
    .box {
      position: relative;
      display: block;
      width: 100%;
      height: 100px;
      line-height: 100px;
      box-sizing: border-box;
      padding: 0 40px;
      background: url(../img/pc/common/plus.png) right 15px center no-repeat;
      &.active {
        background: #ff9900 url(../img/pc/common/minus.png) right center no-repeat;
        color: #fff;
        height: 100px;
        line-height: 100px;
        margin: 2px 0;
      }
      &.shiryou {
        height: 100px;
        line-height: 100px;
        background: #143f80;
        color: #fff;
      }
      &.shiryou2 {
        height: 100px;
        line-height: 100px;
        background: #ff9900;
        color: #fff;
      }
    }
  }
  .category {
    background-color: #fde6c5;
    overflow: hidden;
    box-sizing: border-box;
    display: none;
    .wrap {
      position: relative;
      text-align: left;
    }
    .ttl {
      color: #ff9900;
      font-weight: bold;
      a {
        color: #ff9900;
      }
    }
    .sl {
      border-top: 2px dotted #143f80;
      height: 100px;
      line-height: 100px;
      padding: 0 72px;
      box-sizing: border-box;
      background: url(../img/pc/common/arrow.png) right 15px center no-repeat;
      a {
        display: block;
        height: 100%;
      }
    }
  }
  .icon {
    padding: 20px 0 0 0;
  }
}

#menu {
  position: absolute;
  width: 120px;
  z-index: 15;
  top: 0;
  left: 0;
  transition: left .5s;
  background: #fff;
  font-size: 14px;
  &.start {
    left: 0;
  }
  a:hover {
    opacity: 1;
  }
  .li {
    text-align: center;
    &.on {
      background-position: left bottom !important;
      background-color: #ff9900;
      .box {
        color: #fff;
        border-top: 2px solid #ff9900;
        border-left: 2px solid #ff9900;
        border-right: 2px solid #ff9900;
      }
      .category {
        display: block;
      }
    }
    &.school {
      background: url(../img/pc/menu/school.png) left top no-repeat;
      .category {
        background: url(../img/pc/menu/bg_01.jpg) right bottom no-repeat;
      }
    }
    &.gakuen {
      background: url(../img/pc/menu/gakuen.png) left top no-repeat;
      .category {
        background: url(../img/pc/menu/bg_02.jpg) right bottom no-repeat;
      }
    }
    &.gakka {
      background: url(../img/pc/menu/gakka.png) left top no-repeat;
      .category {
        background: url(../img/pc/menu/bg_03.jpg) right bottom no-repeat;
      }
    }
    &.shiken {
      background: url(../img/pc/menu/shiken.png) left top no-repeat;
      .category {
        background: url(../img/pc/menu/bg_04.jpg) right bottom no-repeat;
      }
    }
    &.tsushin .category {
      background: url(../img/pc/menu/bg_05.jpg) right bottom no-repeat;
    }
    .box {
      position: relative;
      display: block;
      width: 120px;
      height: 120px;
      color: #000;
      border-top: 2px solid #e2e2e3;
      border-left: 2px solid #e2e2e3;
      border-right: 2px solid #e2e2e3;
      box-sizing: border-box;
      &.shiryou {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border: none;
        background: #143f80;
        color: #fff;
      }
      &.small {
        height: 76px;
        .txt {
          bottom: 28px;
        }
      }
      &.small2r {
        height: 80px;
        line-height: 1.5;
        border-bottom: 2px solid #e2e2e3;
        .txt {
          bottom: 18px;
        }
      }
    }
  }
  .category {
    position: absolute;
    left: 120px;
    top: 120px;
    background-color: #fff;
    height: 558px;
    overflow: hidden;
    box-sizing: border-box;
    border-top: 2px solid #e2e2e3;
    border-right: 2px solid #e2e2e3;
    border-bottom: 2px solid #e2e2e3;
    display: none;
    width: 320px;
    .wrap {
      position: relative;
      text-align: left;
      padding: 30px 0 0 20px;
    }
    .ttl {
      color: #ff9900;
      font-size: 21px;
      font-weight: bold;
      a {
        color: #ff9900;
      }
    }
    .ul {
      padding: 20px 0 0;
    }
    .sl {
      margin: 0 0 10px 0;
      font-size: 14px;
      img {
        vertical-align: middle;
        margin: 0 0 2px;
      }
    }
  }
  .icon {
    padding: 20px 0 0 0;
  }
  .txt {
    position: absolute;
    width: 100%;
    bottom: 20px;
  }
}

@media screen and (max-width: 1280px) {
  #menu {
    left: -100px;
  }
}

#footer {
  background: #143f80;
  height: 200px;
  color: #fff;
  font-size: 14px;
  .content-block {
    height: 100%;
  }
  .ul {
    padding: 20px 0 35px 0;
    text-align: center;
    .li {
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
    }
    .shokuin {
      width: 160px;
      border-right: 1px dotted #fff;
    }
    .shiryou {
      width: 220px;
      border-right: 1px dotted #fff;
    }
    .privacy {
      width: 160px;
      text-align: center;
      border-right: 1px dotted #fff;
    }
    .ijime {
      width: 160px;
      text-align: center;
      margin: 0 30px 0 0;
    }
    .contact {
      margin: 0 10px 0 0;
    }
    .button {
      display: block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      background: #fff;
      font-size: 12px;
      color: #143f80;
      text-align: center;
    }
    a {
      color: #fff;
    }
  }
  .info {
    text-align: center;
    p {
      display: inline-block;
      vertical-align: top;
    }
    .seiei {
      width: 264px;
      margin-right: 12px;
      margin-top: 2px;
    }
    .text {
      line-height: 19px;
      margin-right: 20px;
      text-align: left;
    }
    .facebook {
      margin-right: 5px;
    }
  }
  .copyright {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 20px;
    img {
      vertical-align: middle;
    }
  }
}

.content-wrapper {
  position: relative;
  padding: 0 0 0 20px;
}

.base-wrap {
  position: relative;
  padding: 20px 0 40px;
}

.content-block {
  position: relative;
  width: 960px;
  margin: 0 auto;
  z-index: 11;
}

.btnback {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.section {
  overflow: hidden;
}

.arrow-right {
  margin-right: 5px;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  &:before {
    content: "";
    display: table;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

strong {
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

.strong {
  font-weight: bold;
}

.subject {
  position: relative;
  padding: 40px 0;
  line-height: 32px;
}

.maillink {
  font-weight: bold;
  text-decoration: underline;
}

.button {
  width: 460px;
  height: 60px;
  line-height: 60px;
  background: #e2e2e3;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  border: none;
  display: block;
  cursor: pointer;
  &.ora {
    background: #ff9900;
    font-size: 21px;
    height: 80px;
    line-height: 80px;
    color: #fff;
    a {
      color: #fff;
    }
  }
  &.small {
    width: 240px;
    color: #fff;
    height: 60px;
    line-height: 60px;
  }
  a {
    display: block;
    font-weight: bold;
    height: 100%;
  }
}

.button-link {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-weight: 18px;
  text-align: center;
  border: 2px solid #f58f01;
  box-sizing: border-box;
  margin: 15px 0 0;
  font-size: 14px;
  display: block;
  a {
    display: block;
    font-weight: bold;
    height: 100%;
  }
}

#content .page-intro {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  color: #143f80;
  font-size: 18px;
  line-height: 32px;
  background: #fff url(../img/pc/top/bg_intro.jpg) center no-repeat;
  .wrap {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -253px;
  }
  .read {
    position: relative;
    padding: 100px 0 125px;
  }
}

#news {
  position: relative;
  padding-bottom: 80px;
  .news-media {
    position: absolute;
    right: 0;
    top: 80px;
    .news-media-wrapper {
      position: relative;
      overflow: hidden;
      width: 240px;
      height: 180px;
      img {
        width: 100%;
      }
    }
  }
  .news-ul {
    padding: 40px 0 0;
  }
  .news-li {
    overflow: hidden;
    margin: 0 0 15px;
  }
  .date {
    float: left;
    font-weight: bold;
    width: 150px;
  }
  .subject a {
    text-decoration: underline;
  }
}

.page-top {
  opacity: 0;
  .h3 a {
    color: #fff;
    font-weight: bold;
  }
  #main {
    padding-bottom: 60px;
    .slider-wrapper {
      position: relative;
    }
    .banner {
      position: absolute;
      bottom: 15px;
      left: 20px;
      z-index: 6;
      cursor: pointer;
    }
    .banner2 {
      position: absolute;
      bottom: 15px;
      left: 270px;
      z-index: 6;
      cursor: pointer;
    }
    .banner3 {
      position: absolute;
      bottom: 15px;
      left: 520px;
      z-index: 6;
      cursor: pointer;
    }
    .copy {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
    }
    .read {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      color: #143f80;
      font-size: 21px;
      margin-top: -37px;
    }
    .slider {
      position: relative;
      overflow: hidden;
    }
    .thumbs {
      position: absolute;
      overflow: hidden;
      bottom: -30px;
      right: 0;
      z-index: 10;
      .li {
        position: relative;
        float: left;
        width: 100px;
        height: 75px;
        cursor: pointer;
        &.active .cover {
          opacity: 0;
        }
        .cover {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0.4;
        }
      }
    }
  }
  #guide {
    .content-block {
      background: #fff;
      padding-bottom: 80px;
    }
    .h3 {
      width: 310px;
      height: 60px;
      line-height: 60px;
      background-color: #ff9900;
      color: #fff;
      font-size: 21px;
      margin: 0 0 30px;
      img {
        vertical-align: middle;
        margin: 0 5px 2px 0;
      }
    }
  }
  #life {
    .content-block {
      padding-bottom: 60px;
      background: #fff;
      &.up {
        overflow: hidden;
        padding: 0;
        height: 640px;
      }
    }
    .h3 {
      float: right;
      width: 410px;
      height: 60px;
      line-height: 60px;
      background-color: #ff9900;
      color: #fff;
      font-size: 21px;
      margin: 0 0 0 70px;
      text-align: right;
      img {
        vertical-align: middle;
        margin: 0 5px 2px 0;
      }
    }
    .slider-wrapper {
      position: absolute;
      width: 100%;
      overflow: hidden;
      z-index: 12;
      top: 90px;
      left: 0;
      .inner {
        position: relative;
        margin: 0 auto;
        width: 960px;
      }
      .sul {
        position: relative;
        width: 5040px;
      }
      .al {
        position: absolute;
        top: 80px;
        left: 35px;
        z-index: 13;
        cursor: pointer;
      }
      .ar {
        position: absolute;
        top: 80px;
        right: 35px;
        z-index: 13;
        cursor: pointer;
      }
      .sul {
        overflow: hidden;
      }
      .sli {
        float: left;
        width: 400px;
        margin: 0 20px 0 0;
      }
      .info {
        padding: 30px 0 0;
        overflow: hidden;
        font-size: 14px;
      }
      .left {
        float: left;
        width: 200px;
        font-weight: bold;
      }
      .right {
        float: left;
        width: 200px;
        line-height: 24px;
      }
      .label {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 32px;
      }
      .label_j {
        margin-top: 10px;
        font-size: 21px;
      }
      .sch {
        line-height: 32px;
      }
    }
    .key {
      padding: 20px 0 14px;
    }
    .arrow {
      vertical-align: middle;
      margin: 0 6px 2px;
    }
  }
  #history {
    padding-bottom: 80px;
    .img-title {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .left, .right {
      float: left;
      width: 50%;
    }
    .h01 {
      padding: 60px 0 0 70px;
    }
    .h02 {
      padding: 20px 0 30px 20px;
    }
    .h03 {
      padding: 0 0 0 22px;
    }
    .txt {
      padding: 30px 0 0;
      line-height: 32px;
    }
  }
  #dream {
    padding-bottom: 80px;
    .img-title {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
    .left {
      float: left;
      width: 360px;
    }
    .right {
      float: left;
      width: 600px;
    }
    .d02 {
      padding: 30px 0 0 50px;
    }
    .d03 {
      padding: 90px 0 0;
    }
    .txt {
      padding: 25px 0 0 80px;
      line-height: 32px;
    }
  }
  #gakka {
    .content-block {
      background: #fff;
      padding-bottom: 80px;
    }
    .h3 {
      width: 310px;
      height: 60px;
      line-height: 60px;
      background-color: #ff9900;
      color: #fff;
      font-size: 21px;
      margin: 0 0 30px;
      img {
        vertical-align: middle;
        margin: 0 5px 2px 0;
      }
    }
    .ul {
      overflow: hidden;
      font-weight: bold;
      text-align: center;
    }
  }
  #shiken {
    .content-block {
      background: #fff;
      padding-bottom: 80px;
    }
    .left {
      padding: 90px 0 0;
      width: 100%;
      float: none;
    }
    .txt {
      padding: 0 0 40px;
      line-height: 32px;
    }
    .h3 {
      float: right;
      width: 410px;
      height: 60px;
      line-height: 60px;
      background-color: #ff9900;
      color: #fff;
      font-size: 21px;
      margin: 0 0 0 70px;
      text-align: right;
      img {
        vertical-align: middle;
        margin: 0 5px 2px 0;
      }
    }
    .set {
      position: relative;
      overflow: hidden;
      top: -20px;
    }
    .s1 {
      position: relative;
      top: -50px;
      padding: 0 0 0 180px;
    }
    .s2 {
      top: 0;
      padding: 0 0 0 47px;
    }
    .s3 {
      position: absolute;
      top: 0;
      right: 0;
    }
    .openc {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.category-index {
  font-size: 16px;
  line-height: 32px;
  .content-block {
    background: #fff;
    padding-bottom: 80px;
  }
  .flow {
    overflow: hidden;
    .flow-left {
      float: left;
      width: 550px;
      .title, .cnt-detail .title2 {
        padding: 0 0 5px;
        margin: 0;
        font-size: 18px;
        color: #143f80;
      }
    }
  }
}

.cnt-detail .category-index .flow .flow-left .title2 {
  padding: 0 0 5px;
  margin: 0;
  font-size: 18px;
  color: #143f80;
}

.category-index {
  .flow {
    .flow-left .sub {
      margin: 0 0 15px;
      font-size: 14px;
      line-height: 24px;
    }
    .flow-right {
      float: right;
      font-weight: bold;
      text-align: center;
      .key {
        padding: 0px 0 10px;
      }
    }
  }
  .ul {
    width: 962px;
    overflow: hidden;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
  }
  .li {
    float: left;
    width: 238px;
    a {
      display: block;
    }
    &.small {
      height: 96px;
      line-height: 96px;
    }
    &.middle {
      width: 318px;
    }
    &.large, &.home_schooling {
      width: 478px;
    }
    &.full {
      float: left;
      width: 958px;
      text-align: left;
      border-right: 2px solid #e2e2e3;
    }
  }
  .wrap {
    font-size: 16px;
  }
  .key {
    padding: 20px 0 10px;
  }
  .arrow {
    vertical-align: middle;
    margin: 0 6px 2px 0;
  }
  .label {
    display: table;
    height: 40px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    .txt {
      text-align: center;
      vertical-align: middle;
      display: table-cell;
    }
  }
}

#exam {
  .top {
    .left {
      float: left;
      width: 320px;
    }
    .right {
      float: left;
      width: 630px;
      .wrap {
        padding: 20px 0 0 20px;
        line-height: 22px;
        .note {
          font-weight: normal;
        }
      }
    }
    .ico {
      padding: 5px 0 0 5px;
    }
    .note {
      padding: 8px 0;
    }
    .term {
      .date {
        background: #ff9900;
        color: #fff;
        width: 50px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }
      .date-info {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .key {
      padding: 20px 0 10px;
    }
    .arrow {
      vertical-align: middle;
      margin: 0 6px 2px 0;
    }
  }
  &.open {
    .icon {
      padding: 60px 0 30px;
      text-align: center;
    }
    .infomation {
      position: relative;
      line-height: 28px;
      .cnt-detail {
        padding-top: 20px;
        padding-bottom: 0;
      }
    }
    .info-dt {
      padding: 20px 0 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
    }
    .person-info {
      font-size: 14px;
      padding: 0 0 0 160px;
      font-weight: normal;
    }
    .sh {
      .date {
        font-weight: bold;
        font-size: 18px;
        line-height: 36px;
        padding: 30px 0 20px;
      }
      .recruit-block {
        position: relative;
        width: 840px;
        margin: 0 0 0 96px;
        display: table;
        border-collapse: separate;
        border-spacing: 0px 5px;
      }
      .rwrap {
        display: table-row;
        margin: 0 0 5px;
      }
      .label {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        width: 160px;
        background: #ff9900;
      }
      .value {
        display: table-cell;
        vertical-align: middle;
        padding: 0 0 0 30px;
      }
    }
    .program {
      position: relative;
      padding: 60px 0 100px;
      h4 {
        padding: 0 0 30px;
        font-weight: bold;
        font-size: 18px;
      }
      .ul {
        position: relative;
        width: 960px;
        margin: 0 0 40px;
        border-left: 1px solid #143f80;
        border-top: 1px solid #143f80;
        overflow: hidden;
        .li {
          float: left;
          width: 319px;
          height: 95px;
          border-right: 1px solid #143f80;
          border-bottom: 1px solid #143f80;
          display: table;
          background: #dae8ff;
          > p {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
          }
        }
      }
      .note {
        line-height: 28px;
        padding: 0 0 40px;
      }
      .bold {
        font-weight: bold;
      }
    }
    .profile {
      position: relative;
      display: block;
      background: url(../img/pc/exam/open/arrow-right.png) right center no-repeat;
      height: 100%;
    }
    #profile {
      position: relative;
      display: none;
      padding: 0 0 40px;
      .photo {
        position: absolute;
        right: 40px;
      }
    }
  }
}

#exam-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  .control-wrapper {
    padding: 50px 0 30px;
    overflow: hidden;
  }
  ul {
    position: relative;
    overflow: hidden;
    width: 50000px;
    left: 0;
  }
  li {
    float: left;
    width: 320px;
  }
}

.cnt-detail {
  padding: 60px 0 100px;
  &.t0 {
    padding-top: 0 !important;
  }
  &.b0 {
    padding: 60px 0 0;
  }
  &.b80 {
    padding: 60px 0 80px;
  }
  &.b100 {
    padding: 60px 0 100px;
  }
  .title2 {
    font-size: 16px;
    padding: 20px 0 0;
    letter-spacing: 5px;
  }
  .ssub {
    font-weight: bold;
    padding: 20px 0 20px;
    &.normal {
      font-weight: normal;
    }
    &.alarge {
      font-size: 18px;
    }
  }
  .desc {
    line-height: 32px;
    .wrap {
      overflow: hidden;
      padding: 0 0 40px;
    }
    .wrap-inner-box {
      padding: 0 0 0 20px;
      margin: 0 auto 20px;
      width: 800px;
      border: 4px solid #ff9900;
      a {
        height: 54px;
        line-height: 54px;
        color: #143f80;
        font-weight: bold;
      }
    }
    .wrap-inner {
      position: relative;
      padding: 30px 34px;
      border: 4px solid #ff9900;
    }
    .msg-info {
      font-size: 14px;
      line-height: 24px;
      .name {
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
        padding: 0 0 16px;
      }
    }
    .small-wrap {
      margin: 0 0 10px;
    }
    .left {
      float: left;
      width: 320px;
      margin: 0 45px 0 0;
    }
    .left-half, .right-half {
      float: left;
      width: 50%;
    }
    .left240 {
      float: left;
      width: 240px;
    }
    .right {
      float: right;
      width: 320px;
      margin: 0 0 0 45px;
    }
    .right360 {
      float: right;
      width: 360px;
      margin: 0 0 0 40px;
    }
    .right480 {
      float: right;
      width: 280px;
      margin: 0 0 0 40px;
    }
    .right640 {
      float: right;
      width: 640px;
    }
    .note {
      padding: 10px 0 8px;
      font-size: 14px;
      text-align: center;
    }
    .teacher-label {
      position: absolute;
      top: 0;
      left: 0;
      height: 44px;
      line-height: 44px;
      width: 265px;
      background: #ff9900;
      color: #fff;
      text-align: center;
      letter-spacing: 5px;
    }
    .wrap-inner {
      padding: 20px 30px 20px;
    }
    .name {
      padding: 25px 0 0;
      font-weight: bold;
      text-align: left;
      span {
        font-size: 18px;
      }
    }
    .teacher {
      padding: 30px 0 0;
    }
  }
  .cnt-section {
    position: relative;
    margin: 0 0 50px;
    &:last-child {
      margin: 0;
    }
  }
  .uu {
    overflow: hidden;
    padding: 15px 0 0;
    .uli {
      float: left;
      width: 236px;
      line-height: 22px;
    }
  }
  .ul {
    position: relative;
    overflow: hidden;
    width: 990px;
    padding: 30px 0 0;
    .li {
      float: left;
      width: 300px;
      margin: 0 30px 0 0;
    }
    .cat-sec {
      border: 2px solid #e2e2e3;
    }
    .cat-title {
      height: 48px;
      line-height: 48px;
      font-weight: bold;
      text-align: center;
    }
    .cat-photo {
      padding: 0 8px 8px;
    }
    .cat-detail {
      padding: 15px 0 0;
      font-size: 12px;
      line-height: 22px;
    }
    .key {
      padding: 20px 0 18px;
    }
    .arrow {
      vertical-align: middle;
      margin: 0 6px 2px 0;
    }
  }
  .scene {
    padding: 0 0 0 0;
  }
}

.visual img {
  position: relative;
  z-index: 2;
}

#about {
  &.message {
    position: relative;
    #page-visual {
      overflow: initial;
    }
    .txt {
      padding: 20px 0 0;
      line-height: 32px;
    }
    .wrap-inner {
      .left {
        width: 312px;
        margin: 0;
      }
      .right {
        width: 570px;
        margin: 0;
      }
      .name {
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
        padding: 0 0 16px;
      }
    }
    .msg-info {
      line-height: 24px;
    }
  }
  &.history {
    .prevent-page-box {
      position: absolute;
      width: 400px;
      right: 0;
      top: 0;
      a {
        display: block;
        border: 4px solid #ff9900;
        width: 500px;
        height: 60px;
        line-height: 60px;
        color: #143f80;
        text-align: center;
        font-weight: bold;
      }
    }
    .history01 {
      overflow: hidden;
      line-height: 32px;
      .ul {
        padding: 30px 0;
        li {
          float: left;
          width: 240px;
          text-align: center;
        }
        .name {
          padding: 20px 0 0;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .ul2 {
        padding: 30px 0;
        li {
          float: left;
          width: 120px;
          text-align: center;
        }
        .name {
          padding: 20px 0 0;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .right {
        float: left;
        width: 300px;
        margin: 0 0 0 30px;
        text-align: center;
        .name {
          padding: 5px 0 10px;
          line-height: 1.5;
          font-size: 14px;
        }
      }
      .left {
        float: right;
        width: 300px;
        margin: 0 0 0 30px;
        text-align: center;
        .name {
          padding: 15px 0 10px;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .frame {
        padding: 30px 0;
        text-align: center;
        .name {
          padding: 15px 0 0;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .history02 {
      overflow: hidden;
      .ul {
        padding: 0;
        li {
          float: left;
          width: 120px;
          text-align: center;
        }
        .name {
          padding: 20px 0 0;
          font-size: 14px;
        }
      }
      .txt {
        padding: 0 0 30px;
        line-height: 32px;
      }
    }
    .history03 {
      position: relative;
      line-height: 32px;
      &.purple {
        background: #f3ecf7;
        border: 2px solid #cdb4e0;
        color: #6a2e7b;
        margin: 0 0 70px;
        .h3 {
          height: 180px;
          line-height: 180px;
          text-align: center;
          font-size: 21px;
          font-weight: bold;
        }
        .left460, .right460 {
          width: 478px;
          padding: 20px;
          box-sizing: border-box;
        }
      }
      .m30 {
        margin: 0 0 30px;
      }
      .left {
        width: 420px;
        float: left;
      }
      .left460 {
        width: 460px;
        float: left;
      }
      .right {
        width: 500px;
        float: right;
      }
      .right460 {
        width: 460px;
        float: right;
      }
      .photo2 {
        margin: 0 0 30px;
        img:last-child {
          margin: 0 0 0 8px;
        }
      }
    }
    .history04 {
      position: relative;
      line-height: 32px;
      .m30 {
        margin: 0 0 30px;
      }
      .left {
        width: 420px;
        float: left;
      }
      .right {
        width: 500px;
        float: right;
      }
      .photo2 {
        margin: 0 0 30px;
        img:last-child {
          margin: 0 0 0 10px;
        }
      }
    }
    .history05 {
      overflow: hidden;
      .ul {
        padding: 0;
        li {
          float: left;
          text-align: center;
        }
        .name {
          padding: 20px 0 0;
          font-size: 14px;
        }
      }
      .txt {
        padding: 0 0 30px;
        line-height: 32px;
      }
    }
  }
  &.history-detail {
    .prevent-page-box {
      position: absolute;
      width: 400px;
      right: 0;
      top: 0;
      a {
        display: block;
        border: 4px solid #ff9900;
        width: 500px;
        height: 60px;
        line-height: 60px;
        color: #143f80;
        text-align: center;
        font-weight: bold;
      }
    }
    .outline-wrap {
      position: relative;
      padding: 0 0 0 40px;
      margin: 0 0 60px;
    }
    .outline-wrap-block {
      position: relative;
      .block {
        padding: 0 0 20px;
        &:last-child {
          padding: 0;
        }
      }
      .label {
        display: inline-block;
        width: 180px;
        font-weight: bold;
        vertical-align: top;
      }
      .value {
        display: inline-block;
        width: 720px;
        vertical-align: top;
      }
    }
    .outline-circle {
      position: absolute;
      top: 5px;
      left: 5px;
      height: 99%;
      background: #143f80;
      width: 3px;
      .circle-top {
        position: absolute;
        top: 0;
        left: -7px;
        line-height: 1;
        width: 18px;
        height: 18px;
      }
      .circle-bottom {
        position: absolute;
        bottom: 0;
        left: -2px;
        line-height: 1;
        height: 8px;
        width: 8px;
      }
    }
  }
  &.school {
    .school-logo {
      text-align: center;
      padding-top: 20px;
      .info {
        padding: 0 30px 0 0;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      .right-half .info {
        padding: 20px 0px 0 30px;
      }
    }
    .sound {
      position: absolute;
      right: 0;
      top: 0;
    }
    .kouka {
      padding: 0 0 40px;
      &:nth-child(2n) {
        padding: 0 0 40px 100px;
      }
      p {
        display: inline-block;
        vertical-align: top;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
        margin: 0 10px 0 0;
      }
    }
  }
  &.org .desc {
    padding: 40px 0 0;
    min-height: 1000px;
  }
  &.idea {
    .read {
      padding: 40px 0;
      line-height: 28px;
    }
    .wrap-inner a {
      height: 54px;
      line-height: 54px;
      color: #143f80;
      font-weight: bold;
    }
    .perpose {
      margin: 0 0 15px;
      &:last-child {
        margin: 0;
      }
      .label {
        font-size: 21px;
        font-weight: bold;
        width: 120px;
        display: inline-block;
        vertical-align: middle;
      }
      .txt {
        vertical-align: middle;
        display: inline-block;
      }
    }
    .cnt-detail {
      line-height: 32px;
    }
    .edu {
      .section-box {
        position: relative;
        font-size: 24px;
        width: 990px;
        text-align: center;
        color: #fff;
        overflow: hidden;
        padding: 0 0 40px;
        .sbox {
          float: left;
          width: 300px;
          margin: 0 30px 0 0;
          height: 100px;
          display: table;
        }
        p {
          display: table-cell;
          vertical-align: middle;
          line-height: 32px;
        }
      }
      .section {
        padding: 0 0 40px;
      }
    }
    .focus {
      line-height: 20px;
    }
  }
  &.evaluation {
    .read {
      padding: 40px 0;
    }
    .wrap-inner {
      padding: 0 0 0 20px;
      margin: 0 auto 20px;
      width: 800px;
      a {
        height: 54px;
        line-height: 54px;
        color: #143f80;
        font-weight: bold;
      }
    }
  }
}

#course {
  &.course {
    .course-ul {
      width: 970px;
      overflow: hidden;
      .course-li {
        float: left;
        width: 320px;
      }
      .course-li-r {
        float: left;
        width: 240px;
      }
    }
    .goal {
      position: relative;
      overflow: hidden;
      margin: 0 0 40px;
      .left {
        width: 300px;
        float: left;
        margin: 0 40px 0 0;
      }
      .right {
        float: left;
        width: 620px;
        .cnt-wrap {
          border: 2px solid #ff9900;
        }
        .head {
          color: #000;
          background: #ff9900;
        }
      }
      .cnt-wrap {
        border: 2px solid #83cdd2;
        height: 274px;
        box-sizing: border-box;
      }
      .head {
        color: #143f80;
        background: #83cdd2;
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      .cnt {
        line-height: 32px;
        padding: 15px;
        text-align: center;
        .age-wrap {
          text-align: left;
          .age {
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: #ff9900;
            display: inline-block;
            vertical-align: middle;
            margin: 0 16px 5px 0;
            text-align: center;
          }
          .age-val {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.5;
          }
        }
      }
    }
    .ex {
      position: relative;
      padding: 20px 0 30px 180px;
      line-height: 32px;
      .name {
        font-size: 18px;
        font-weight: bold;
        padding: 0 0 8px;
      }
      .icon {
        position: absolute;
        top: 20px;
        left: 0;
      }
    }
    .years {
      position: relative;
      overflow: hidden;
      width: 990px;
      margin: 20px 0 30px;
      .year {
        position: relative;
        border: 2px solid #f59494;
        float: left;
        width: 296px;
        height: 360px;
        margin: 0 30px 0 0;
      }
      .bold {
        font-weight: bold;
      }
      .next-nav {
        position: absolute;
        top: 50%;
        height: 100%;
        right: -25px;
        z-index: 10;
        margin-top: -25px;
      }
      .head {
        background: #f59494;
        line-height: 45px;
        height: 45px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .cnt {
        padding: 15px;
        line-height: 1.5;
      }
    }
  }
  &.tour {
    .read {
      position: relative;
      padding: 40px 0 40px;
    }
    .tour-ul {
      position: relative;
      width: 962px;
      overflow: hidden;
      margin: 0 auto 60px;
      border-left: 1px solid #e2e2e3;
      .tour-li {
        width: 478px;
        float: left;
        border-right: 1px solid #e2e2e3;
        border-top: 1px solid #e2e2e3;
        border-bottom: 1px solid #e2e2e3;
        text-align: center;
        font-weight: bold;
        padding: 20px;
        box-sizing: border-box;
      }
      img {
        width: 100%;
      }
      .h3 {
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        color: #fff;
        background: #143f80;
      }
      .info {
        padding: 20px 0 0;
        line-height: 32px;
        font-size: 14px;
      }
    }
  }
}

#exam {
  &.course {
    .cnt-detail {
      padding-bottom: 0;
      padding-left: 0;
    }
    .content-block {
      padding: 0;
    }
    .course-desc {
      padding: 20px 0 0;
    }
    .course-ul {
      width: 970px;
      overflow: hidden;
      .course-li {
        float: left;
        width: 320px;
      }
      .course-li-r {
        float: left;
        width: 240px;
      }
    }
    .goal {
      position: relative;
      overflow: hidden;
      margin: 0 0 40px;
      .left {
        width: 300px;
        float: left;
        margin: 0 40px 0 0;
      }
      .right {
        float: left;
        width: 620px;
        .cnt-wrap {
          border: 2px solid #ff9900;
        }
        .head {
          color: #000;
          background: #ff9900;
        }
      }
      .cnt-wrap {
        border: 2px solid #83cdd2;
        height: 274px;
        box-sizing: border-box;
      }
      .head {
        color: #143f80;
        background: #83cdd2;
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
      .cnt {
        line-height: 32px;
        padding: 15px;
        text-align: center;
        .age-wrap {
          text-align: left;
          .age {
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: #ff9900;
            display: inline-block;
            vertical-align: middle;
            margin: 0 16px 5px 0;
            text-align: center;
          }
          .age-val {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.5;
          }
        }
      }
    }
    .ex {
      position: relative;
      padding: 20px 0 30px 180px;
      line-height: 32px;
      .name {
        font-size: 18px;
        font-weight: bold;
        padding: 0 0 8px;
      }
      .icon {
        position: absolute;
        top: 20px;
        left: 0;
      }
    }
    .years {
      position: relative;
      overflow: hidden;
      width: 990px;
      margin: 20px 0 30px;
      .year {
        position: relative;
        border: 2px solid #f59494;
        float: left;
        width: 296px;
        height: 360px;
        margin: 0 30px 0 0;
      }
      .bold {
        font-weight: bold;
      }
      .next-nav {
        position: absolute;
        top: 50%;
        height: 100%;
        right: -25px;
        z-index: 10;
        margin-top: -25px;
      }
      .head {
        background: #f59494;
        line-height: 45px;
        height: 45px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
      .cnt {
        padding: 15px;
        line-height: 1.5;
      }
    }
  }
  &.tour {
    .read {
      position: relative;
      padding: 40px 0 40px;
    }
    .tour-ul {
      position: relative;
      width: 962px;
      overflow: hidden;
      margin: 0 auto 60px;
      border-left: 1px solid #e2e2e3;
      .tour-li {
        width: 478px;
        float: left;
        border-right: 1px solid #e2e2e3;
        border-top: 1px solid #e2e2e3;
        border-bottom: 1px solid #e2e2e3;
        text-align: center;
        font-weight: bold;
        padding: 20px;
        box-sizing: border-box;
      }
      img {
        width: 100%;
      }
      .h3 {
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        color: #fff;
        background: #143f80;
      }
      .info {
        padding: 20px 0 0;
        line-height: 32px;
        font-size: 14px;
      }
    }
  }
}

#front {
  &.download {
    .apply {
      position: absolute;
      top: 40px;
      right: 0;
      a {
        color: #fff;
      }
    }
    .label {
      text-align: left;
      padding: 0 0 10px;
    }
    h5 {
      padding: 20px 0 0;
      font-size: 14px;
      font-weight: bold;
    }
    table {
      width: 100%;
      th, td {
        border: 2px solid #143f80;
        vertical-align: middle;
        padding: 10px;
        text-align: right;
        line-height: 1.3;
      }
      .kind {
        text-align: left;
      }
    }
    .left {
      width: 46%;
      margin: 0;
      text-align: left;
    }
    .right {
      width: 54%;
      margin: 0;
      text-align: left;
    }
    .pdf .wrap-inner-box {
      padding: 0;
      box-sizing: border-box;
    }
    .pdf-txt {
      height: 124px;
      line-height: 124px;
      text-align: center;
      margin: 0px 0 0 -40px;
    }
    .note {
      padding: 10px 0 0;
      text-align: left;
    }
    .details {
      border-top: 2px solid #143f80;
      padding: 30px 0 0;
      line-height: 28px;
      margin: 30px 0 0;
      .txt {
        padding: 0px 0 40px;
      }
    }
    span.bo {
      font-weight: bold;
      text-align: left;
    }
  }
  &.access {
    #map {
      padding: 0px 0 80px;
    }
    #googlemap-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      padding: 30px;
      box-sizing: border-box;
    }
    #googlemap-side-wrapper, #googlemap {
      position: relative;
      height: 100%;
    }
    .apply {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &.recruit {
    .recruit-block {
      position: relative;
      width: 840px;
      margin: 0 0 0  96px;
      display: table;
      border-collapse: separate;
      border-spacing: 0px 5px;
    }
    .rwrap {
      display: table-row;
      margin: 0 0 5px;
    }
    .label {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      width: 200px;
      background: #ff9900;
    }
    .value {
      display: table-cell;
      vertical-align: middle;
      padding: 0 0 0 30px;
    }
    .select-wrapper {
      position: relative;
      overflow: hidden;
      padding: 40px 0 0;
      width: 1000px;
    }
    .select-block {
      width: 210px;
      box-sizing: border-box;
      border: 2px solid #143f80;
      display: inline-block;
      height: 80px;
      line-height: 76px;
      background: #dae8ff;
      vertical-align: middle;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .ar {
      display: inline-block;
      vertical-align: middle;
      margin: 0 8px 0;
      img {
        vertical-align: middle;
      }
    }
  }
}

#school {
  &.club {
    .banner {
      text-align: center;
      padding: 20px 0;
    }
    .cat-detail p {
      line-height: 20px;
      margin: 0 0 10px;
    }
  }
  &.uniform {
    .tabs {
      position: relative;
      border-top: 2px solid #143f80;
      border-bottom: 2px solid #143f80;
      border-left: 2px solid #143f80;
      overflow: hidden;
      width: 641px;
      margin: 0 auto;
    }
    .tab {
      cursor: pointer;
      position: relative;
      border-right: 2px solid #143f80;
      overflow: hidden;
      float: left;
      width: 319px;
      text-align: center;
      font-size: 18px;
      &.active {
        padding: 5px;
        box-sizing: border-box;
        p {
          background: #ff9900;
          color: #fff;
          height: 46px;
          line-height: 46px;
        }
      }
      p {
        height: 56px;
        line-height: 56px;
      }
    }
    .cnt-wrapper {
      position: relative;
      padding: 40px 0 0;
      height: 1020px;
      text-align: center;
      .uni {
        position: absolute;
        width: 100%;
        height: 1020px;
        &.winter {
          background: #fff url(../img/pc/school/uniform/01.jpg) center top no-repeat;
        }
        &.summer {
          background: #fff url(../img/pc/school/uniform/02.jpg) center top no-repeat;
        }
      }
      .desc {
        position: absolute;
        width: 100%;
        bottom: 0;
        .h3 {
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 40px;
        }
      }
    }
  }
}

#home_schooling.top .schedule, #exam.top .schedule {
  border-bottom: 1px solid #e2e2e3;
}

#home_schooling.top .schedule-ul, #exam.top .schedule-ul {
  border-top: 1px solid #e2e2e3;
  padding: 30px 0;
  overflow: hidden;
}

#home_schooling.top .schedule-ul .schedule-li, #exam.top .schedule-ul .schedule-li {
  float: left;
  width: 240px;
}

#home_schooling.top .schedule-ul h3, #exam.top .schedule-ul h3 {
  font-weight: bold;
  padding: 0 0 20px;
  font-size: 24px;
  font-family: Helvetica, Arial, sans-serif;
}

#home_schooling.top .schedule-ul .cnt, #exam.top .schedule-ul .cnt {
  line-height: 32px;
}

#home_schooling.about .cnt-detail, #exam.about .cnt-detail {
  padding-left: 0;
}

#home_schooling.about .left, #exam.about .left {
  float: left;
  width: 380px;
}

#home_schooling.about .right, #exam.about .right {
  float: left;
  width: 580px;
}

#home_schooling.about .info, #exam.about .info {
  padding: 30px 0 0;
}

#home_schooling.life .photo-txt, #exam.life .photo-txt {
  padding: 0px 0 15px;
  font-weight: bold;
}

#home_schooling.life .note, #exam.life .note {
  padding: 15px 0 0;
  font-size: 14px;
  line-height: 22px;
}

#home_schooling.life .life-ul, #exam.life .life-ul {
  position: relative;
  width: 1000px;
  overflow: hidden;
}

#home_schooling.life .life-li, #exam.life .life-li {
  float: left;
  width: 300px;
  margin: 0 30px 20px 0;
  height: 350px;
}

#home_schooling.way .course-ul, #exam.way .course-ul {
  width: 970px;
  overflow: hidden;
}

#home_schooling.way .course-ul .course-li, #exam.way .course-ul .course-li {
  float: left;
  width: 320px;
}

#home_schooling.way .course-ul .course-li-r, #exam.way .course-ul .course-li-r {
  float: left;
  width: 240px;
}

#home_schooling.way .ways, #exam.way .ways {
  position: relative;
  overflow: hidden;
  width: 1000px;
  color: #fff;
  padding: 0 0 20px;
}

#home_schooling.way .way-li, #exam.way .way-li {
  float: left;
  width: 300px;
  margin: 0 30px 0 0;
}

#home_schooling.way .way-li h5, #exam.way .way-li h5 {
  text-align: center;
  padding: 25px 0;
  font-size: 24px;
  background: #6eb0ed;
}

#home_schooling.way .way-li .way-cnt, #exam.way .way-li .way-cnt {
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  background: #4289e1;
  height: 200px;
  box-sizing: border-box;
}

#home_schooling.way h4, #exam.way h4 {
  font-weight: bold;
  font-size: 18px;
  margin: 0px 0 10px;
}

#home_schooling.way .left, #exam.way .left, #home_schooling.way .right, #exam.way .right {
  width: 50%;
  margin: 0;
}

#home_schooling.guide .tabs, #exam.guide .tabs {
  position: relative;
  border-top: 2px solid #143f80;
  border-bottom: 2px solid #143f80;
  border-left: 2px solid #143f80;
  overflow: hidden;
  width: 961px;
}

#home_schooling.guide .tab, #exam.guide .tab {
  cursor: pointer;
  position: relative;
  border-right: 2px solid #143f80;
  overflow: hidden;
  float: left;
  width: 319px;
  text-align: center;
  font-size: 18px;
  a {
    display: inline-block;  
    width: 100%;
    cursor: pointer;
    text-decoration: none;
  }
}

#home_schooling.guide .tab.active, #exam.guide .tab.active {
  padding: 5px;
  box-sizing: border-box;
}

#home_schooling.guide .tab.active p, #exam.guide .tab.active p {
  background: #ff9900;
  color: #fff;
  height: 46px;
  line-height: 46px;
}

#home_schooling.guide .tab p, #exam.guide .tab p {
  height: 56px;
  line-height: 56px;
}

#home_schooling.guide .cnt, #exam.guide .cnt {
  line-height: 32px;
  padding: 50px 0 0px;
  display: none;
}

#home_schooling.guide .cnt .h3, #exam.guide .cnt .h3 {
  font-weight: bold;
  font-size: 18px;
}

#home_schooling.guide .cnt .wrap, #exam.guide .cnt .wrap {
  padding: 0 0 30px;
}

#home_schooling.guide.points .h3, #exam.guide.points .h3 {
  font-size: 18px;
  font-weight: bold;
}

#home_schooling.guide.points .m20, #exam.guide.points .m20 {
  margin-bottom: 20px;
}

#home_schooling.guide.points .cnt-wrapper:first-child .wrap:last-child, #exam.guide.points .cnt-wrapper:first-child .wrap:last-child {
  margin-bottom: 0;
}

#home_schooling.guide.points .cnt-detail, #exam.guide.points .cnt-detail {
  padding: 60px 0 0;
}

#home_schooling.guide.points .wrap, #exam.guide.points .wrap {
  padding-bottom: 40px;
}

#home_schooling.guide.points .wrap h3, #exam.guide.points .wrap h3 {
  margin-bottom: 10px;
}

#home_schooling.guide.points .wrap .attention, #exam.guide.points .wrap .attention {
  margin-top: 40px;
  padding: 20px;
  border: 2px solid #143f80;
  color: #1b3c78;
}

#home_schooling.guide.points .txt, #exam.guide.points .txt {
  font-size: 14px;
  line-height: 24px;
}

#home_schooling.guide.points table, #exam.guide.points table {
  margin-bottom: 20px;
}

#home_schooling.guide.points table {
  th, td {
    font-size: 14px;
    text-align: center;
  }
}

#exam.guide.points table {
  th, td {
    font-size: 14px;
    text-align: center;
  }
}

#home_schooling.guide.points table th, #exam.guide.points table th {
  padding: 0;
  width: 16.666%;
  font-weight: bold;
}

#home_schooling.guide.points table .align-left, #exam.guide.points table .align-left {
  padding-left: 20px;
  text-align: left;
}

#home_schooling.guide.points .small, #exam.guide.points .small {
  font-size: 12px;
}

#home_schooling.guide.points {
  .suisen th, .ippan th {
    height: 40px;
    width: 190px;
  }
}

#exam.guide.points {
  .suisen th, .ippan th {
    height: 40px;
    width: 190px;
  }
}

#home_schooling.guide.points .suisen .align-left, #exam.guide.points .suisen .align-left {
  height: 60px;
}

#home_schooling.guide.points .ippan .align-left, #exam.guide.points .ippan .align-left {
  height: 90px;
}

#home_schooling.guide.points {
  .common01 th, .common02 th {
    height: 60px;
    width: 190px;
  }
}

#exam.guide.points {
  .common01 th, .common02 th {
    height: 60px;
    width: 190px;
  }
}

#home_schooling.guide.points .common01 .h240, #exam.guide.points .common01 .h240 {
  height: 240px;
}

#home_schooling.guide.points .common02 .cnt-wrap, #exam.guide.points .common02 .cnt-wrap {
  margin-bottom: 20px;
}

#home_schooling.guide.points .common03 th, #exam.guide.points .common03 th {
  height: 60px;
  width: 140px;
}

#home_schooling.guide.points .common03 td, #exam.guide.points .common03 td {
  height: 60px;
  padding: 0;
}

#home_schooling.guide.points .common04 table, #exam.guide.points .common04 table {
  margin: 20px 0;
}

#home_schooling.guide.points .common04 th, #exam.guide.points .common04 th {
  height: 40px;
}

#home_schooling.guide.points table .h60, #exam.guide.points table .h60 {
  height: 60px;
}

#home_schooling.guide.points table .ora, #exam.guide.points table .ora {
  background-color: #ffcc99;
}

#home_schooling.guide .section .cnt-wrap.one, #exam.guide .section .cnt-wrap.one {
  margin: 0px 0 20px;
}

#home_schooling.guide .section .cnt-wrap.one:first-child, #exam.guide .section .cnt-wrap.one:first-child {
  margin: 0px 0 40px;
}

#home_schooling.guide .section .left, #exam.guide .section .left {
  float: left;
  width: 460px;
  margin: 0px 20px 0 0;
}

#home_schooling.guide .section .right, #exam.guide .section .right {
  float: left;
  width: 460px;
  margin: 0px 0 0 20px;
}

#home_schooling.guide .section .h4, #exam.guide .section .h4 {
  font-size: 18px;
  padding: 0 0 10px;
}

#home_schooling.guide .section table, #exam.guide .section table {
  width: 100%;
}

#home_schooling.guide .section th, #exam.guide .section th {
  width: 150px;
  padding: 10px 15px;
  border: 2px solid #143f80;
}

#home_schooling.guide .section .big, #exam.guide .section .big {
  font-size: 18px;
  text-align: right;
  font-weight: bold;
}

#home_schooling.guide .section td, #exam.guide .section td {
  width: 280px;
  padding: 10px 15px;
  border: 2px solid #143f80;
  text-align: right;
}

#home_schooling.guide .section .sum, #exam.guide .section .sum {
  font-weight: bold;
  font-size: 18px;
  background: #ff9900;
}

#errors.way {
  .course-ul {
    width: 970px;
    overflow: hidden;
    .course-li {
      float: left;
      width: 320px;
    }
    .course-li-r {
      float: left;
      width: 240px;
    }
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    margin: 0px 0 10px;
  }
}

#content .read {
  padding: 0 0 40px;
}

#form-wrapper {
  position: relative;
  width: 800px;
  margin: 0 auto;
  &.confirm .h3 {
    height: auto;
    line-height: 1;
  }
  &.complete {
    border-top: 1px solid #143f80;
  }
  .form-section {
    display: block;
    border-top: 2px solid #143f80;
    border-bottom: 2px solid #143f80;
    &.first {
      border-bottom: none;
      .h3 {
        padding: 20px 0;
      }
      .inputstyle {
        border-bottom: none;
        padding: 0 0 30px;
      }
    }
  }
  .inputstyle {
    padding: 30px 0;
    overflow: hidden;
    border-bottom: 1px solid #e2e2e3;
  }
  .label {
    float: left;
    width: 300px;
    .note {
      padding: 15px 0 0;
    }
  }
  .notice {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 15px;
  }
  .h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  .checkblock {
    border: 2px solid #e2e2e3;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px;
  }
  .value-block {
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .h3 {
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
  }
  .input {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 20px;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid #e2e2e3;
    border-radius: 4px;
    width: 360px;
    &.small {
      width: 120px;
    }
    &.middle {
      width: 220px;
    }
    &.full {
      width: 100%;
    }
    &.m10 {
      margin: 0 10px 0 0;
    }
  }
  .textarea {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    border: 2px solid #e2e2e3;
    border-radius: 4px;
    padding: 10px 20px;
  }
  .box {
    position: relative;
    margin: 0 0 15px;
    text-align: left;
  }
  .radio-block {
    padding: 10px 0 10px;
    .radio {
      display: inline-block;
      margin: 0 10px 0 0;
      font-size: 16px;
    }
  }
  .select-num {
    position: relative;
    display: inline-block;
    margin: 0 0 0 10px;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      height: 50px;
      width: 80px;
      padding: 0 0 0 10px;
      background: #e2e2e3 url(../img/pc/select-arrow.png) right center no-repeat;
    }
  }
  .select-block {
    position: relative;
    &.oneline {
      display: inline-block;
      margin: 0 0 0 20px;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      height: 50px;
      width: 300px;
      padding: 0 0 0 10px;
      background: #e2e2e3 url(../img/pc/select-arrow.png) right center no-repeat;
    }
  }
  .require {
    display: inline-block;
    width: 50px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background: #ff9900;
    vertical-align: middle;
    margin: 0 0 4px 2px;
    font-size: 12px;
    border-radius: 4px;
  }
  .small {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    padding: 0 0 0 10px;
  }
  .value-box {
    float: left;
    width: 500px;
  }
  .buttons {
    padding: 40px 0 0;
    font-weight: bold;
    .buttons {
      font-size: 18px;
    }
    .last {
      margin-top: 20px;
    }
  }
}

#blog {
  .blog-cnt {
    float: left;
    width: 640px;
    margin: 0px 40px 0 0;
  }
  .blog-sidebar {
    float: right;
    width: 280px;
    font-size: 14px;
    .swrap {
      margin: 0 0 30px;
    }
    .ss-ul {
      position: relative;
      padding: 10px 0 0;
    }
    .ss-ulr {
      position: relative;
      padding: 10px 0 0;
      .ss-li {
        padding: 15px 0px;
        line-height: 1.5;
        border-bottom: 1px solid #e2e2e3;
        height: auto;
      }
    }
    .ss-li {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #e2e2e3;
    }
    .select-block {
      position: relative;
      padding: 20px 0;
      select {
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        height: 40px;
        width: 100%;
        padding: 0 0 0 10px;
        background: #e2e2e3 url(../img/pc/select-arrow.png) right center no-repeat;
      }
    }
  }
  &.index {
    .list .li {
      position: relative;
      padding: 0 0 40px;
      border-bottom: 2px solid #e2e2e3;
      margin: 0 0 40px;
    }
    .desc-little {
      font-size: 14px;
    }
    .photo {
      float: right;
      width: 160px;
      margin: 80px 0 0 20px;
    }
    .desc-little {
      line-height: 32px;
      overflow: hidden;
      a {
        text-decoration: underline;
      }
    }
    .paginate {
      text-align: center;
      .page-numbers {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        box-sizing: border-box;
        border: 1px solid #ff9900;
        margin: 0 2px;
        font-size: 14px;
        &.current {
          background: #ff9900;
          color: #fff;
        }
      }
    }
  }
  .dt {
    .blog-desc {
      line-height: 32px;
      font-size: 14px;
      p {
        padding: 5px 0;
      }
    }
    .images {
      position: relative;
      .prev {
        position: absolute;
        top: 50%;
        left: 10px;
        z-index: 10;
        margin-top: -25px;
      }
      .next {
        position: absolute;
        z-index: 10;
        top: 50%;
        right: 10px;
        margin-top: -25px;
      }
      .slider {
        margin: 30px auto;
        background: #000;
        width: 480px;
        height: 360px;
      }
    }
    .page {
      position: relative;
      border-top: 2px solid #e2e2e3;
      margin: 20px 0 0;
      padding: 40px 0 0;
      text-align: center;
      > p {
        display: inline-block;
        vertical-align: middle;
        &:last-child {
          margin: 0 0 0 30px;
        }
        img {
          vertical-align: middle;
        }
      }
    }
  }
}

/*# sourceMappingURL=app.css.map */
